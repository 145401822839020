import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import { Typography } from "@mui/material";
import DOMPurify from "dompurify";

import "react-quill/dist/quill.snow.css"; // Importa lo stile di Quill
import "./markdown.css";

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"], // Rimuove la formattazione
    ],
    handlers: {
      image: function () {
        const range = this.quill.getSelection();
        const url = prompt("Inserisci l'URL dell'immagine:");

        if (url) {
          this.quill.insertEmbed(range.index, "image", url);
          const [image] = this.quill.root.querySelectorAll(`img[src="${url}"]`);
          if (image) {
            image.style.maxHeight = "50vh";
            image.style.maxWidth = "50vw";
          }
        }
      },
    },
  },
};


// Funzione di debounce
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(handler); // Pulisce il timeout quando il valore cambia
  }, [value, delay]);

  return debouncedValue;
};

// Editor Component
export function MarkDownEditor({ value, onChange, limit=1000000, placeholder="Scrivi qui con formattazione (grassetto, corsivo, elenchi, ecc.)" }) {
  const [internalValue, setInternalValue] = useState(value);
  const [warning, setWarning] = useState(""); // Stato per gestire l'avviso

  const debouncedValue = useDebounce(internalValue, 500); // Debounce di 500ms

  useEffect(() => {
    if (debouncedValue !== value && debouncedValue) {
      onChange(debouncedValue); // Aggiorna solo dopo il debounce
    }
  }, [debouncedValue, onChange, value]);

  useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  const handleChange = (content) => {
    setInternalValue(content);

    // Controlla la lunghezza del contenuto
    const contentLength = content?.length || 0;
    if (contentLength > limit) {
      setWarning(
        "Hai superato il limite di caratteri! Riduci le dimensioni delle immagini o riduci il testo. Il salvataggio risulterà parziale."
      );
    } else {
      setWarning(""); // Nascondi l'avviso se sotto il limite
    }
  };

  return (
    <Typography variant='body1' className="editor-container">
      <ReactQuill
        theme="snow"
        value={internalValue} // Assicura che ReactQuill abbia sempre un valore stringa
        onChange={handleChange}
        placeholder={placeholder}
        modules={modules}
        style={{ marginBottom: "16px" }}
      />
      {/* Avviso se supera il limite */}
      {warning && (
        <div style={{ color: "red", marginTop: "10px" }}>{warning}</div>
      )}
    </Typography>
  );
}

// Visualizer Component
export function MarkDownVisualizer({
  value,
  variant = "body1",
  component = "div",
  ...rest
}) {
  // Sanifica il valore HTML per sicurezza
  const sanitizedValue = DOMPurify.sanitize(value);

  return (
    <Typography
      className="editor-container"
      variant={variant}
      component={component} // Necessario per inserire HTML
      {...rest} // Altri attributi come stile o className
      dangerouslySetInnerHTML={{ __html: sanitizedValue }}
    />
  );
}
